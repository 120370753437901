import axios from 'axios';
import { captureException, captureMessage } from '@sentry/browser';
import userStore from '../../stores/userStore';
import { penyuService } from './penyu-service';
import { patrickStarService } from './patrick-star-service';
import { Auth } from '@efishery/sdk-core';
import { dataBrokerService } from './data-broker-service';
import { flagrService } from './flagr-service';

const isDev = window.location.host !== 'dashin.efishery.com';
const {
  REACT_APP_JWT: staticToken,
  REACT_APP_SERVICE_API: apiUrl,
  REACT_APP_SERVICE_AUTH: serviceAuth,
  REACT_APP_FARM_SERVICE: farmService,
  REACT_APP_FEEDER_OPPS: feederOps,
  REACT_APP_CLIENT_ID: clientId,
  REACT_APP_ENV: stage,
  REACT_APP_IS_ENABLE_AUTHV2: flagAuth2,
  REACT_APP_AIOTOPS_DATA_BROKER_TOKEN: aiotOpsDataBrokerToken,
  REACT_APP_SERVICE_SUPPORT: supportServiceUrl,
  // eslint-disable-next-line no-undef
} = process.env;

export const sdkauth = new Auth(clientId, stage);
export const isAuth2Enable = flagAuth2 === 'true';

const rest = axios.create();
const cancelToken = axios.CancelToken;
const source = cancelToken.source();
rest.defaults.cancelToken = source.token;
rest.defaults.baseURL = '/api/';

// disable due not used on all
// rest.interceptors.request.use(
//   (config) => {
//     const token = `Bearer ${userStore.getToken()}`;
//     if (token) {
//       config.headers["Authorization"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

rest.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err?.config;
    const shouldNotRefreshTokenOnUrl = [
      'auth/logout',
      'auth/login',
      'auth/sso',
    ].includes(originalConfig.url);

    if (
      !shouldNotRefreshTokenOnUrl &&
      !originalConfig?.sent &&
      [403, 401].includes(err?.response?.status)
    ) {
      captureMessage('Unauthorized User', 'info');

      originalConfig.sent = true;

      // Access Token was expired
      try {
        const rs = isAuth2Enable
          ? await getRefreshToken()
          : await rest.post('/auth/refreshtoken', {
              refresh_token: userStore.getRefreshToken(),
            });

        const { token } = rs.data;
        userStore.updateToken(token);

        return rest(originalConfig);
      } catch (_error) {
        source.cancel('canceled by user');
        if (isAuth2Enable) sdkauth.logout();
        userStore.logout();
        window.location.reload(true);
        return Promise.reject(_error);
      }
    }

    captureException(err, (scope) => {
      scope.setExtra('Error response', err.response);
    });

    return Promise.reject(err);
  }
);

const getRefreshToken = async () => {
  const tokenData = await sdkauth.persistRefresh();
  return {
    data: {
      token: tokenData?.accessToken,
    },
  };
};

export const apiLeads = {
  getPagination: ({ limit = 20, skip = 0 }) =>
    rest.get(`leads?limit=${limit}&skip=${skip}`),
  search: (term) => rest.get(`leads/search`, { params: { q: term } }),
  searchAllType: (term) =>
    rest.get(`leads/searchAllType`, { params: { q: term } }),
  saveDuplicate: (data) => rest.post(`leads/add_leads_duplicate_logs`, data),
  save: (data) => rest.post(`leads`, data),
  getByLeadsID: (leadsID) => rest.get(`leads/${leadsID}`),
  edit: (docID, data) => rest.put(`leads/${docID}`, data),
  delete: (docID) => rest.delete(`leads/${docID}`),
  notification: (leadsID, method = 'sms') =>
    rest.post(`leads/notification`, { leads_id: leadsID, method }),
};

export const apiUsers = {
  getUsers: async (params) => {
    try {
      const res = await rest.get(`/users`, { params });
      return res.data;
    } catch (err) {
      return err;
    }
  },
  postUser: async (params) => {
    try {
      const res = await rest.post(`/users`, params);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  getUserEmails: (params) => rest.get('users/emails', { params }),
  getUserDetail: (id, params) => rest.get(`users/${id}`, { params }),
};

export const apiRequestShipments = {
  getPoint: async () => {
    try {
      const res = await rest.get(`v1/shipments`);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  postShipment: async (params) => {
    try {
      const res = await rest.post(`v1/shipments`, { params });
      return res.data;
    } catch (err) {
      return err;
    }
  },
};

export const apiLeadsV2 = {
  getLeads: async (params) => {
    try {
      const res = await rest.get(`v2/leads`, { params });
      return res.data;
    } catch (err) {
      return err;
    }
  },
  getAllLeads: async (params) => {
    try {
      const res = await rest.get(`v2/leads/all`, { params });
      return res.data;
    } catch (err) {
      return err;
    }
  },
  addLead: async (payload) => {
    try {
      const res = await rest.post('v2/leads', payload);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  getLeadById: async (id) => {
    try {
      const res = await rest.get(`v2/leads/${id}`);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  updateLead: async (id, data) => {
    try {
      const res = await rest.patch(`v2/leads/${id}`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  mergeLead: async (data) => {
    try {
      const res = await rest.patch(`v2/leads/merge`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  getLeadTransactionStatus: async (id) => {
    try {
      const res = await rest.get(`v2/leads/${id}/transaction-status`);
      return res.data;
    } catch (err) {
      return err;
    }
  },
  mergeLeadRecommendation: async (payload) => {
    try {
      const res = await rest.post('v2/leads/merge/recommendation', payload);
      return res.data;
    } catch (err) {
      return err;
    }
  },
};

export const apiPoints = {
  getPoints: async () => {
    try {
      const res = await rest.get('/points');
      return res.data;
    } catch (err) {
      return err;
    }
  },
};

export const apiCustomers = {
  generateRequestFeeder: (data) =>
    penyuService.post('feeder/assembly/generate-user-feeders', data),
  // DashOld API
  createRequestFeeder: (data) => rest.post('customers/feeder/request', data),
  getListRequestFeeder: (leadID) =>
    rest.get(`customers/feeder/lead/${leadID}/request`),
  deleteRequestFeeder: (userFeederID) =>
    rest.delete(`customers/feeder/request/${userFeederID}`),
  getListUser: () => rest.get('customers/feeder/users'),
  updateRequestFeeder: (data) =>
    rest.post(`customers/feeder/request/${data.editFID}`, data),
  getListRequestFeederByOwnerId: (ownerID) =>
    rest.get(`customers/feeder/owner/${ownerID}/status/request`),
  getListReadyFeeder: (leadID) =>
    rest.get(`customers/feeder/lead/${leadID}/ready`),
};

export const apiOrders = {
  getOrders: () => rest.get(`orders`),
  getOrdersByQuery: (key, ...args) => rest.get(`orders/${key}/${args}`),
  getOrder: (orderId) => rest.get(`orders/${orderId}`),
  patchOrder: (orderId, params) => rest.patch(`orders/${orderId}`, params),
  getOrdersByFilters: (params) => rest.post(`orders/filters`, params),
  getOrderByOrderID: (params) => rest.post(`orders/orderID`, params),
  cancelAtFeedHub: (params) => rest.put('orders/cancelAtFeedHub', params),

  // Order Solvay
  getOrderDPS: (data) =>
    rest.get(`orders/solvay/aqualisan/getOrderDPS?parameters=${data}`),
  getOrderDpsByID: (id, batch_id) =>
    rest.get(
      `orders/solvay/aqualisan/getOrderByID?id=${id}&batch_id=${batch_id}`
    ),
  draftOrderDPS: (data) =>
    rest.put('orders/solvay/aqualisan/draftOrderDPS', data),
  cancelOrderDPS: (data) =>
    rest.put('orders/solvay/aqualisan/cancelOrderDPS', data),
  validateOrderDPS: (data) =>
    rest.put('orders/solvay/aqualisan/validateOrderDPS', data),
  reqShipmentOrderDPS: (data) =>
    rest.put('orders/solvay/aqualisan/reqShipmentOrderDPS', data),
  getWarehouses: () => rest.get('orders/solvay/aqualisan/getWarehouses'),

  // New API
  getList: async (params) => {
    const response = await patrickStarService.get('miss-karen/order/feeders', {
      params,
    });
    return response.data.data;
  },
  getDetail: async (orderId) => {
    const response = await patrickStarService.get(
      `miss-karen/order/feeders/${orderId}/order-id`
    );
    return response.data.data;
  },
  getActivityLog: async (orderId, page, per_page) => {
    const response = await patrickStarService.get(
      `miss-karen/order/feeders/${orderId}/logs?page=${page}&per_page=${per_page}`
    );
    return response.data.data;
  },
  submitApproveOrReject: async (payload) => {
    const response = await patrickStarService.put(
      'miss-karen/order/feeders/status',
      payload
    );
    return response.data.data;
  },
  getTotalOrder: async (params) => {
    const response = await patrickStarService.get(
      'miss-karen/order/feeders/count',
      { params }
    );
    return response.data.data;
  },
  getListCommodities: async (limit) => {
    const response = await axios.get(
      `${supportServiceUrl}/commodities?limit=${limit}`,
      {
        headers: {
          'X-APP-TOKEN': userStore.getToken(),
          'X-CLIENT-ID': 'DASHIN',
        },
      }
    );
    return response.data.data;
  },
  submitValidate: async (payload) => {
    const response = await patrickStarService.post(
      'miss-karen/order/feeders/validate',
      payload
    );
    return response.data.data;
  },
};

export const apiLocations = {
  getProvinces: () => rest.get(`location/provinces`),
  getRegencies: (provinceId) => rest.get(`location/regencies/${provinceId}`),
  getDistricts: (regencyId) => rest.get(`location/districts/${regencyId}`),
  getSubDistricts: (districtId) =>
    rest.get(`location/sub-districts/${districtId}`),
};

export const apiCommodities = {
  getCommodities: () => rest.get(`commodity`),
  getByLeadsID: (leads_id) => rest.get(`commodity/leads/${leads_id}`),
};

export const apiAuth = {
  login: (user) => rest.post(`auth/login`, user),
  logout: () => rest.get(`auth/logout`),
  verify: (code) => rest.post(`${serviceAuth}/google/verify`, code),
  sso: () => rest.get(`auth/sso`),
};

export const apiEvent = {
  getPagination: ({ limit = 20, skip = 0 }) =>
    rest.get(`event?limit=${limit}&skip=${skip}`),
  search: (term) => rest.get(`event/search`, { params: { q: term } }),
  save: (data) => rest.post(`event`, data),
  getByCategory: (category) => rest.get(`event/category/${category}`),
  getByChannelID: (channelID) => rest.get(`event/${channelID}`),
  edit: (docID, data) => rest.put(`event/${docID}`, data),
  delete: (docID) => rest.delete(`event/${docID}`),
};

export const apiPricings = {
  getPagination: ({ limit = 20, skip = 0 }) =>
    rest.get(`pricings?limit=${limit}&skip=${skip}`),
  search: (q) => rest.get(`pricings/search`, { params: { q } }),
  save: (data) => rest.post(`pricings`, data),
  getItem: (_id) => rest.get('pricings/' + _id),
  edit: (_id, data) => rest.put('pricings/' + _id, data),
  delete: (_id) => rest.delete('pricings/' + _id),
};

export const apiPromos = {
  getCode: async (code) => {
    const response = await patrickStarService.get(`miss-karen/promos/${code}`);
    return response.data.data;
  },
  getList: async (params) => {
    const response = await patrickStarService.get('miss-karen/promos', {
      params,
    });
    return response.data.data;
  },
  create: async (payload) => {
    const response = await patrickStarService.post(
      'miss-karen/promos',
      payload
    );
    return response.data.data;
  },
  update: async (code, payload) => {
    const response = await patrickStarService.put(
      `miss-karen/promos/${code}`,
      payload
    );
    return response.data.data;
  },
};

export const apiBilling = {
  create: (data) => rest.post('billings', data),
  getList: ({ page, per_page, search, order }) =>
    rest.get(
      `billings?page=${page}&per_page=${per_page}&search=${search}&order=${order}`
    ),
  getLogTransaction: ({ leadsID, start_date, end_date }) =>
    rest.get(
      `billings/log-transaction?leadsID=${leadsID}&start_date=${start_date}&end_date=${end_date}`
    ),
  getInvoices: ({
    leadsID,
    start_date,
    end_date,
    page,
    per_page,
    search,
    order,
    status,
  }) =>
    rest.get(
      `billings/invoices?leadsID=${leadsID}&start_date=${start_date}&end_date=${end_date}&page=${page}&per_page=${per_page}&search=${search}&order=${order}&status=${status}`
    ),
  getCustomersAR: ({ point_name, due_date_to }) =>
    rest.get(
      `billings/customers?point_name=${point_name}&due_date_to=${due_date_to}`
    ),

  // Reminders
  createReminder: (data) => rest.post('billings/reminders', data),
  getListReminder: ({ page, order, category }) =>
    rest.get(
      `billings/reminders?page=${page}&order=${order}&category=${category}`
    ),
  getDetailReminder: (uuid) => rest.get(`billings/reminders/view/${uuid}`),
  getRecipients: ({ broadcast_uuid, per_page }) =>
    rest.get(
      `billings/reminders/recipients?broadcast_uuid=${broadcast_uuid}&per_page=${per_page}`
    ),
  updateReminder: (data) => rest.put(`billings/reminders/update`, data),
  deleteReminder: (broadcast_uuid) =>
    rest.delete(`billings/reminders/delete/${broadcast_uuid}`),
};

export const apiBroadcast = {
  send: (data) => rest.post(`broadcast/send`, data),
};

export const apiOperations = new (function () {
  return {
    getOptions: (query, tokenCancel) =>
      rest.get(query, {
        cancelToken: tokenCancel,
      }),
  };
})();

export const apiSubmissions = {
  postSubmission: (params) => rest.post(`submissions`, params),
  getSubmission: () => rest.get(`submissions`),
};

export const apiFeeds = {
  getPagination: ({ limit = 20, skip = 0 }) =>
    rest.get(`feeds?limit=${limit}&skip=${skip}`),
  search: (term) => rest.get(`feeds/search`, { params: { q: term } }),
  save: (data) => rest.post(`feeds`, data),
  getByFeedsId: (_id) => rest.get(`feeds/${_id}`),
  edit: (_id, data) => rest.put(`feeds/${_id}`, data),
  delete: (_id) => rest.delete(`feeds/${_id}`),
};

export const serviceAPI = {
  getPoints: () =>
    rest.get(`${apiUrl}/points`, {
      headers: {
        'x-app-token': staticToken,
      },
    }),
};
export const apiInventories = {
  listWarehouse: (params) =>
    rest.get('inventories/warehouses', {
      params,
    }),
  detailWarehouse: (id) => rest.get(`inventories/warehouse/${id}`),
  listProduct: (params) =>
    rest.get('inventories', {
      params,
    }),
  addTransfer: (data) =>
    rest.post('inventories/transfer', {
      data,
    }),
  editTransfer: (id, data) =>
    rest.patch(`inventories/transfer/${id}`, {
      data,
    }),
  listTransfer: (params) => rest.get('inventories/transfer', { params }),
  detailTransfer: (id) => rest.get(`inventories/transfer/${id}`),
  updateStatusTransfer: (id, data) =>
    rest.patch(`inventories/transfer/update-status/${id}`, { data }),
};
export const apiOutstandings = {
  list: () => rest.get('outstandings'),
};
export const apiTickets = {
  list: ({ limit = 20, skip = 0, status = '', activity_type = '' }) =>
    rest.get(
      `tickets?limit=${limit}&skip=${skip}&activity_type=${activity_type}&status=${status}`
    ),
  getById: (id) => rest.get(`tickets/${id}`),
  update: (data) => rest.put(`tickets/${data._id}`, { data }),
  reload: (data) => rest.put(`tickets/reload/${data._id}`, { data }),

  createTicketService: (data) => rest.post('tickets/service/create', data),
  getListTicketService: (query) =>
    rest.get(`tickets/service/list?query=${JSON.stringify(query)}`),
  updateTicketService: (data) => rest.put('tickets/service/update', data),
  validateTicketAssembly: (data) =>
    penyuService.put('feeder/assembly/assign-fts', data),
};

export const apiTicketAssembly = {
  getList: ({
    status = '',
    order_id,
    customer_name,
    page = 1,
    per_page = 10,
  }) =>
    penyuService.get('tickets/assembly', {
      params: { status, order_id, customer_name, page, per_page },
    }),
  getDetail: (ulid) => penyuService.get(`tickets/assembly/${ulid}`),
  getCount: (states) =>
    penyuService.get('tickets/assembly/count', { params: { states } }),
  generateRequestFeeder: (data) =>
    penyuService.post('tickets/assembly/generate-user-feeders', data),
  assignFTS: (data) => penyuService.put('tickets/assembly/assign-fts', data),
  updateStatus: (ulid, data) =>
    penyuService.put(`tickets/assembly/${ulid}/status`, data),
};

export const apiTicketInstallation = {
  getList: ({
    status = '',
    order_id,
    customer_name,
    page = 1,
    per_page = 10,
  }) =>
    penyuService.get('tickets/installation', {
      params: { status, order_id, customer_name, page, per_page },
    }),
  getDetail: (ulid) => penyuService.get(`tickets/installation/${ulid}`),
  getCount: (state) =>
    penyuService.get('tickets/installation/count', { params: { state } }),
  updateStatus: (ulid, data) =>
    penyuService.put(`tickets/installation/${ulid}/status`, data),
  assignFTS: (data) =>
    penyuService.put('tickets/installation/assign-fts', data),
};

const headers = {
  Authorization: `Bearer ${userStore.getToken()}`,
};
export const apiUAC = {
  list: (params) => rest.get(`uac`, { params, headers }),
  getById: (id) => rest.get(`uac/${id}`, { headers }),
  addUac: (data) => rest.post(`uac`, data, { headers }),
  suspendUac: (uuid, action) =>
    rest.post(`uac/${uuid}/${action}`, null, { headers }),
  updateUac: (uuid, data) => rest.put(`uac/${uuid}`, data, { headers }),
  deleteUac: (id) => rest.delete(`uac/${id}`, { headers }),
};

export const customerV2 = {
  getCustomers: (params) =>
    rest.get('v2/customers', { params }).then((res) => res.data),
  getCustomer: (customerId) => rest.get(`v2/customers/${customerId}`),
  patchCustomer: (customerId, params) =>
    rest.patch(`v2/customers/${customerId}`, params),

  addProduct: (data) => rest.post(`v2/customers`, data, { headers }),
  suspendProduct: (uuid, action) =>
    rest.patch(`v2/customers/${uuid}/${action}`, null, { headers }),
  getAppUsers: (userId) => rest.get(`v2/customers/users/${userId}/appusers`),
  getDetailAppUsers: (userId) => rest.get(`v2/customers/appusers/${userId}`),
  addAppUsers: (data) => rest.post(`v2/customers/appusers`, data, { headers }),
  editAppUsers: (userId, data) =>
    rest.put(`v2/customers/appusers/${userId}`, data, { headers }),
};

export const farmerAPI = {
  addCustomer: (data) =>
    rest.post(`${farmService}/budidaya`, data, { headers }),
};

export const apiPermision = {
  list: (params) => rest.get(`permission`, { params, headers }),
  getById: (uuid) => rest.get(`permission/${uuid}`, { headers }),
  addPermission: (data) => rest.post(`permission`, data, { headers }),
  deletePermission: (id) => rest.delete(`permission/${id}`, { headers }),
  updatePermission: (uuid, data) =>
    rest.put(`permission/${uuid}`, data, { headers }),
};

export const newapiPoints = {
  list: (params) => rest.get(`points`, { params, headers }),
  getById: (uuid) => rest.get(`points/${uuid}`, { headers }),
  addpoints: (data) => rest.post(`points`, data, { headers }),
  deletepoints: (id) => rest.delete(`points/${id}`, { headers }),
  updatepoints: (uuid, data) => rest.put(`points/${uuid}`, data, { headers }),
  validatePoints: (data) => rest.post('points/validate', data, { headers })
};

export const apiArea = {
  getCoverage: (params) => rest.get(`area/coverage`, { params, headers }),
  getCoveragePoint: (params) =>
    rest.get(`area/coveragepoint`, { params, headers }),
  getLocation: (params) => rest.get(`area/location`, { params, headers }),
  list: (params) => rest.get(`area`, { params, headers }),
  getById: (uuid) => rest.get(`area/${uuid}`, { headers }),
  addarea: (data) => rest.post(`area`, data, { headers }),
  deletearea: (id) => rest.delete(`area/${id}`, { headers }),
  updatearea: (uuid, data) => rest.put(`area/${uuid}`, data, { headers }),
};

export const apiRegion = {
  list: (params) => rest.get(`region`, { params, headers }),
  getById: (uuid) => rest.get(`region/${uuid}`, { headers }),
  addregion: (data) => rest.post(`region`, data, { headers }),
  deleteregion: (id) => rest.delete(`region/${id}`, { headers }),
  updateregion: (uuid, data) => rest.put(`region/${uuid}`, data, { headers }),
};

export const apiProduct = {
  list: (params) => rest.get(`products`, { params, headers }),
};

export const apiPartners = {
  listPartners: (params) => rest.get(`partner`, { params, headers }),
  getById: (id) => rest.get(`partner/${id}`, { headers }),
  createPartner: (data) => rest.post(`partner`, data, { headers }),
  updatePartner: (uuid, data) => rest.put(`partner/${uuid}`, data, { headers }),
  deletePartner: (id) => rest.delete(`partner/${id}`, { headers }),
  getPartnerById: (id) => rest.get(`partner/detail/${id}`, { headers }),
};

// Ticket Customer Endpoint
const apiFarmer = axios.create({
  baseURL: feederOps,
  headers: {
    'x-app-token': userStore.getToken(),
    'X-Client-Id': 'DASHBOARD-INTERNAL',
  },
});

export const ticketCustomer = {
  getTicketCustomerList: async (
    query,
    searchBy,
    ticketStatus,
    requestStatus,
    type,
    page,
    limit = 10
  ) => {
    const response = await apiFarmer.get(`subscription_requests`, {
      params: {
        ticket_status: ticketStatus,
        request_status: requestStatus,
        [searchBy]: query,
        type,
        page,
        limit,
      },
    });
    return response.data.data;
  },
  submitReason: (id, payload) =>
    apiFarmer.post(`subscription_requests/${id}/validate`, payload),
};

export const ticketReturn = {
  listWarehouse: async (isReturnLocation = false) => {
    const response = await penyuService.get(
      `warehouse?is_return_location=${isReturnLocation}`
    );
    return response.data.data.items;
  },
  getTicketReturnList: async (
    query,
    searchBy,
    status,
    page,
    limit = 10,
    order_by
  ) => {
    const response = await penyuService.get('feeder-return-tickets', {
      params: {
        ticket_status: status,
        [searchBy]: query,
        page,
        limit,
        order_by,
      },
    });
    return response.data.data;
  },
  getDetail: async (ticketId) => {
    const response = await penyuService.get(
      `feeder-return-tickets/${ticketId}`
    );
    return response.data.data;
  },
  getOrderByLead: async (leadId) => {
    const response = await penyuService.get(
      `feeder-return-tickets/order/lists?lead_id=${leadId}&limit=10000`
    );
    return response.data;
  },
  getInvoice: async (orderId) => {
    const response = await penyuService.get(
      `feeder-return-tickets/invoice?order_id=${orderId}`
    );
    return response.data;
  },
  createTicket: (payload) =>
    penyuService.post(`feeder-return-tickets`, payload),
  assignFTS: (id, payload) =>
    penyuService.post(`feeder-return-tickets/${id}/assignfts`, payload),
  resetFTS: (id) =>
    penyuService.post(`feeder-return-tickets/${id}/resetfts`, null),
  closeTicket: (id, payload) =>
    penyuService.put(`feeder-return-tickets/${id}/status`, payload),
};

export const apiSparepart = {
  listLocations: async () => {
    const response = await penyuService.get(`location-stocks?limit=100000`);
    return response.data.data;
  },
  getLocationById: async (id) => {
    const response = await penyuService.get(
      `warehouse/${id}/product?exclude_feeder=true`
    );
    return response.data.data;
  },
  listTransfers: async (status, page, query) => {
    const params = {
      status,
      transfer_code: query,
      limit: 10,
      page,
    };
    const response = await penyuService.get('inventory/transfer', { params });
    return response.data.data;
  },
  filterTransfers: async ({
    status,
    page,
    start_date,
    end_date,
    type,
    createdBy,
    from,
    to,
    transfer_code,
  }) => {
    let params = {
      status,
      type,
      created_by_uuid: createdBy,
      location_from: from,
      location_to: to,
      limit: 10,
      page,
    };

    if (start_date && end_date) {
      params = { ...params, start_date, end_date };
    }

    if (transfer_code) {
      params = { ...params, transfer_code };
    }

    const response = await penyuService.get(`inventory/transfer`, { params });
    return response.data.data;
  },
  detailTransfer: async (id) => {
    const response = await penyuService.get(`inventory/transfer/${id}`);
    return response.data.data;
  },
  getCreatedBy: async () => {
    const response = await penyuService.get(
      `inventory/created-by-transfer?limit=10000`
    );
    return response.data.data;
  },
  createTransfer: async (payload) => {
    const response = await penyuService.post(`inventory/transfer`, payload);
    return response.data.data;
  },
  updateTransfer: async (id, payload) => {
    const response = await penyuService.put(
      `inventory/transfer/${id}/status`,
      payload
    );
    return response.data.data;
  },
  getFTSInventory: async (params) => {
    const response = await penyuService.get('inventory/stocks', { params });
    return response.data.data;
  },
};

export const apiOpenapi = {
  listOpenapiDocs: () => rest.get('openapi', { headers }),
};

export const apiDomains = {
  listDomains: (params) => rest.get(`domain`, { params, headers }),
  createDomain: (data) => rest.post(`domain`, data, { headers }),
  updateDomain: (uuid, data) => rest.put(`domain/${uuid}`, data, { headers }),
  deleteDomain: (id) => rest.delete(`domain/${id}`, { headers }),
  getDomainById: (id) => rest.get(`domain/detail/${id}`, { headers }),
};

export const apiOrganizations = {
  listOrganizations: (params) => rest.get(`organization`, { params, headers }),
  createOrganization: (data) => rest.post(`organization`, data, { headers }),
  updateOrganization: (uuid, data) =>
    rest.put(`organization/${uuid}`, data, { headers }),
  deleteOrganization: (id) => rest.delete(`organization/${id}`, { headers }),
  getOrganizationById: (id) =>
    rest.get(`organization/detail/${id}`, { headers }),
};

export const apiRoles = {
  list: async (params) => rest.get('role', { params, headers }),
  getById: async (uuid) => rest.get(`role/${uuid}`, { headers }),
  create: async (data) => rest.post('role', data, { headers }),
  update: async (uuid, data) => rest.put(`role/${uuid}`, data, { headers }),
  delete: async (uuid) => rest.delete(`role/${uuid}`, { headers }),
};

export const apiPopc = {
  ping: () => rest.get('popc'),
  getRequestedList: (params) =>
    rest.get('popc/mutation-rotation/requested', { params }),
  createRequest: (body) => rest.post('popc/mutation-rotation/create', body),
  getRequestedDetails: (params) =>
    rest.get('popc/mutation-rotation/details', { params }),
  updateRequest: (body) => rest.put('popc/mutation-rotation/update', body),
  getEmployeeList: (params) => rest.get('popc/employee', { params }),
  getRegionalList: () => rest.get('popc/regional'),
  getAreaList: () => rest.get('popc/area'),
  getPointList: () => rest.get('popc/point'),
  getEmployeeListV2: (params) => rest.get('popc/v2/employee', { params }),
  getRegionalAreaV2: (params) => rest.get('popc/v2/regional-area', { params }),
  createDocMR: (body) =>
    rest.post('popc/mutation-rotation/create-document-mr', body),
  getDocMR: (params) =>
    rest.get('popc/mutation-rotation/get-document-mr', { params }),
  createSignRequest: (body) =>
    rest.post('popc/mutation-rotation/create-sign-request', body),
  createDocML: (body) =>
    rest.post('popc/mutation-rotation/create-document-ml', body),
  getDocML: (params) =>
    rest.get('popc/mutation-rotation/get-document-ml', { params }),
  createSignature: (body) =>
    rest.post('popc/mutation-rotation/create-signature', body),
};
export const apiRbac = {
  enforce: (data) => rest.post('rbac/enforce', data, { headers }),
  getPermissions: (params) => rest.get('rbac/permissions', { params, headers }),
  createPermission: (data) => rest.post('rbac/permissions', data, { headers }),
  deletePermission: (data) =>
    rest.post('rbac/permissions/delete', data, { headers }),
  createGroup: async (data) => rest.post('/rbac/groups', data, { headers }),
  getGroups: async (params) => rest.get('/rbac/groups', { params, headers }),
  getRolesByDomain: async (domain) =>
    rest.get(`/rbac/roles/domain/${domain}`, { headers }),
  getPermissionsByDomain: async (domain) =>
    rest.get(`/rbac/permissions/domain/${domain}`, { headers }),
  assignRoles: async (data) => rest.post('/rbac/users', data, { headers }),
  deleteGroup: (data) => rest.post('rbac/groups/delete', data, { headers }),
  getAssociatedGroups: (group, domain) =>
    rest.get(`/rbac/groups/${group}/domain/${domain}`, { headers }),
  getActionsByRoleDomainAndResouce: (subject, domain, resource) =>
    rest.get(
      `/rbac/actions/subject/${subject}/domain/${domain}/resource/${resource}`,
      { headers }
    ),
  getActionsByRoleAndDomain: (subject, domain) =>
    rest.get(`/rbac/actions/subject/${subject}/domain/${domain}`, { headers }),
  getPermissionsOnUser: (email) =>
    rest.get(`/rbac/permissions/user/${email.toLowerCase()}`, { headers }),
};

export const apiDomainsV2 = {
  listDomains: (params) => rest.get(`v2/domain`, { params, headers }),
  deleteDomain: (id) => rest.delete(`v2/domain/${id}`, { headers }),
  createDomain: (data) => rest.post(`v2/domain`, data, { headers }),
  updateDomain: (uuid, data) =>
    rest.put(`v2/domain/${uuid}`, data, { headers }),
  getDomainById: (id) => rest.get(`v2/domain/detail/${id}`, { headers }),
};

export const apiProductManagement = {
  getUoMList: () =>
    patrickStarService
      .get(`krusty-krab/v1/products/uom`)
      .then((res) => res.data.data),
  getProductCategoryList: (params) =>
    patrickStarService.get('krusty-krab/v1/products/categories', { params }),
  getProductList: (params) =>
    patrickStarService.get('krusty-krab/v1/products', { params }),
  getProductDetail: (uuid) =>
    patrickStarService.get(`krusty-krab/v1/products/${uuid}`),
  createProduct: (payload) =>
    patrickStarService.post('krusty-krab/v1/products', payload),
  updateProduct: (uuid, payload) =>
    patrickStarService.put(`krusty-krab/v1/products/${uuid}`, payload),
  deleteProduct: (uuid) =>
    patrickStarService.delete(`krusty-krab/v1/products/${uuid}`),
};

export const apiFileManagerV2 = {
  upload: (payload) =>
    patrickStarService.post('proxy-pass/fms-v2/upload', payload),
};

export const dataBroker = {
  getListTSS: () =>
    dataBrokerService.get('/', {
      headers: {
        'Content-Type': 'application/json',
        'X-Client-Id': 'AIoTOpsTools',
        Authorization: `Bearer ${aiotOpsDataBrokerToken}`,
      },
      params: { question_id: 30765, occurance: 'hourly' },
    }),
};

export const apiVisitManagement = {
  createVisitPlan: (payload) => penyuService.post('visit-plans', payload),
  createDraftVisit: async (payload) => {
    const response = await penyuService.post('visit-plans/draft', payload);
    return response.data.data;
  },
  getVisitPlanList: (params) => penyuService.get('visit-plans', { params }),
  getVisitReportList: async (params) => {
    const response = await penyuService.get('visit-reports', { params });
    return response.data.data;
  },
  getVisitReportDetail: async (code) => {
    const response = await penyuService.get(`v2/visit-reports/${code}`);
    return response.data.data;
  },
  getTotalVisitReport: (params) =>
    penyuService.get('visit-reports/count', { params }),
  reassignVisitReport: (payload) =>
    penyuService.post('visit-reports/reassign', payload),
  updateVisitReportState: (newState, payload) =>
    penyuService.put(`visit-reports/state/${newState}`, payload),
  getVisitAnalytic: async (params) => {
    const response = await penyuService.get(`visit-reports/analytic`, {
      params,
    });
    return response.data.data;
  },
};

export const apiProductionBatch = {
  getListItem: async (params) => {
    const response = await patrickStarService.get('krusty-krab/v1/items', {
      params,
    });
    return response.data.data;
  },
  getDetailItem: async (uuid) => {
    const response = await patrickStarService.get(
      `krusty-krab/v1/items/${uuid}`
    );
    return response.data.data;
  },
  getOperationalLogs: async (uuid, params) => {
    const response = await patrickStarService.get(
      `krusty-krab/v1/items/${uuid}/operational-logs`,
      { params }
    );
    return response.data.data;
  },
  createBulkItem: async (payload) => {
    const response = await patrickStarService.post(
      'krusty-krab/v1/items',
      payload
    );
    return response.data.data;
  },
  refurbishBulkItem: async (payload) => {
    const response = await patrickStarService.post(
      'krusty-krab/v1/items/refurbish',
      payload
    );
    return response.data.data;
  },
  archiveBulkItem: async (payload) => {
    const response = await patrickStarService.post(
      'krusty-krab/v1/items/archive',
      payload
    );
    return response.data.data;
  },
};

export const apiFlagr = {
  getFlagrEvaluation: async ({ flagID, entityID, entityContext }) => {
    const response = await flagrService.post('evaluation', {
      flagID,
      entityType: entityID ? 'user' : 'guest',
      entityID,
      entityContext,
      enableDebug: process.env.REACT_APP_ENV !== 'production',
    });

    return response.data;
  },
  getFlagrEvaluationBatch: async ({
    flagIDs = [],
    entityID,
    entityContext = {},
  }) => {
    const response = await flagrService.post('evaluation/batch', {
      flagIDs,
      entities: [
        {
          entityType: entityID ? 'user' : 'guest',
          entityID,
          entityContext,
        },
      ],
      enableDebug: process.env.REACT_APP_ENV !== 'production',
    });

    return response.data;
  },
};
